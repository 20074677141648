import {
  AppBar,
  AppBarProps,
  Button,
  Container,
  Hidden,
  Toolbar,
} from "@mui/material";
import React from "react";
import { MAMenuButton } from "../menus/MAMenuButton";
import { UserMenuButton } from "../menus/UserMenuButton";
import { AppLogo } from "./AppLogo";
import { ElevationScroll } from "./ElevationScroll";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

type AdminToolbarProps = Omit<AppBarProps, "children">;

function AdminToolbar(props: AdminToolbarProps): JSX.Element {
  return (
    <>
      <ElevationScroll>
        <AppBar color="default" {...props}>
          <Container>
            <Toolbar disableGutters>
              <Hidden smDown>
                <AppLogo />
              </Hidden>

              <Button href="/admin/orders">Заказы</Button>
              <Button href="/admin/users">Пользователи</Button>
              <MAMenuButton />

              <span style={{ flexGrow: 1 }} />

              <UserMenuButton sx={{ ml: 1 }} />
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </>
  );
}

export { AdminToolbar };
