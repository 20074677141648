/**
 * @generated SignedSource<<44804dbdcfc42f95be05576b5059e18d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StatusEnum = "received" | "picking" | "shipping" | "delivered" | "cancelled" | "%future added value";
export type OrdersQuery$variables = {};
export type OrdersQuery$data = {
  readonly orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly readableId: string | null;
        readonly created: string | null;
        readonly total: number | null;
        readonly status: StatusEnum | null;
        readonly items: ReadonlyArray<{
          readonly product: {
            readonly id: string;
            readonly image: string | null;
            readonly name: string | null;
          } | null;
        } | null> | null;
        readonly payment: {
          readonly paymentStatus: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};
export type OrdersQuery = {
  variables: OrdersQuery$variables;
  response: OrdersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "desc": true
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readableId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "format",
      "value": "d MMM"
    }
  ],
  "kind": "ScalarField",
  "name": "created",
  "storageKey": "created(format:\"d MMM\")"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 4
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Payment",
  "kind": "LinkedField",
  "name": "payment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentStatus",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "CartItem",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": "items(limit:4)"
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "orders(orderBy:{\"desc\":true})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "CartItem",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": "items(limit:4)"
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "orders(orderBy:{\"desc\":true})"
      }
    ]
  },
  "params": {
    "cacheID": "a10e9bf13493a0348979562f86ae9732",
    "id": null,
    "metadata": {},
    "name": "OrdersQuery",
    "operationKind": "query",
    "text": "query OrdersQuery {\n  orders(orderBy: {desc: true}) {\n    edges {\n      node {\n        id\n        readableId\n        created(format: \"d MMM\")\n        total\n        status\n        items(limit: 4) {\n          product {\n            id\n            image\n            name\n          }\n          id\n        }\n        payment {\n          paymentStatus\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "63926aac20c82e0145be6bd73e8604bd";

export default node;
