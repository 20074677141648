import { type PaletteMode } from "@mui/material";
import { type PaletteOptions } from "@mui/material/styles";

/**
 * Customized Material UI color palette.
 *
 * @see https://mui.com/customization/palette/
 * @see https://mui.com/customization/default-theme/?expand-path=$.palette
 */
const createPalette = (mode: PaletteMode): PaletteOptions => ({
  mode,
  background: {
    default: "rgba(242,246,252,1)",
  },
  primary: {
    main: "rgba(0,0,0,1)",
  },
  secondary: {
    main: "#7fad26",
  },
  // icons: {
  //   main: "#ffffff",
  // },
  action: {
    // disabledBackground: "", // don't set the disable background color
    disabled: "gray", // set the disable foreground color
  },
});

export { createPalette };
