import { Route } from "../core";
import AdminMAConfigQuery, {
  type AdminMAConfigQuery as AdminMAConfigQueryType,
} from "../queries/AdminMAConfigQuery.graphql";
import { AdminMAConfig } from "./AdminMAConfig";

export default {
  path: "/admin/ma-config",
  query: AdminMAConfigQuery,
  component: () => import(/* webpackChunkName: "admin" */ "./AdminMAConfig"),
  response: (data) => ({
    title: "Настройки алгоритма (админка)",
    description: "Настройки алгоритма",
    props: { fragmentRef: data.maConfig },
  }),
} as Route<AdminMAConfig, AdminMAConfigQueryType>;
