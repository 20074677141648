/**
 * @generated SignedSource<<c9f3b07799ed946e991c58b2cc0cdaed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntellektualnayaPageQuery$variables = {};
export type IntellektualnayaPageQuery$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"IntellektualnayaPageFragment">;
  } | null;
};
export type IntellektualnayaPageQuery = {
  variables: IntellektualnayaPageQuery$variables;
  response: IntellektualnayaPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IntellektualnayaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IntellektualnayaPageFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IntellektualnayaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProgram",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCodeDigits",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCodeDigitsSum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCodeLetters",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentReferralCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentReferralCodeDigits",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentReferralCodeDigitsSum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentReferralCodeLetters",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "intellectualDiscountWord",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f8009e55ac4b9263b5a042f05bbc743",
    "id": null,
    "metadata": {},
    "name": "IntellektualnayaPageQuery",
    "operationKind": "query",
    "text": "query IntellektualnayaPageQuery {\n  me {\n    ...IntellektualnayaPageFragment\n    id\n  }\n}\n\nfragment IntellektualnayaPageFragment on User {\n  maInfo {\n    isInProgram\n    referralCode\n    referralCodeDigits\n    referralCodeDigitsSum\n    referralCodeLetters\n    parentReferralCode\n    parentReferralCodeDigits\n    parentReferralCodeDigitsSum\n    parentReferralCodeLetters\n    id\n  }\n  intellectualDiscountWord\n}\n"
  }
};
})();

(node as any).hash = "c6ce94c68c06b9f195688d318ac4edfd";

export default node;
