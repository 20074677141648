import { type Route } from "../core";
import { type NewsPage } from "./NewsPage";
import NewsPageQuery, {
  NewsPageQuery as NewsPageQueryType,
} from "../queries/NewsPageQuery.graphql";

/**
 * NewsPage route.
 */
export default {
  path: "/novosti",
  query: NewsPageQuery,
  component: () => import(/* webpackChunkName: "NewsPage" */ "./NewsPage"),
  response: (data) => ({
    title: "Новости и акции",
    description:
      "Новости и акции интернет-магазина орехов и сухофруктов MadNuts.ru",
    keywords: "новости, акции, интернет-магазин, орехи, сухофрукты, MadNuts.ru",
    ogDescription:
      "Новости и акции интернет-магазина орехов и сухофруктов MadNuts.ru",
    props: { data },
  }),
} as Route<NewsPage, NewsPageQueryType>;
