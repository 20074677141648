import AccountSettingsRoute from "./AccountSettings.route";
import AdminRoute from "./Admin.route";
import AdminMAConfigRoute from "./AdminMAConfig.route";
import AdminOrdersRoute from "./AdminOrders.route";
import AdminStatsRoute from "./AdminStats.route";
import AdminStatsActiveOrdersRoute from "./AdminStatsActiveOrders.route";
import AdminUserDetailsRoute from "./AdminUserDetails.route";
import AdminUsers from "./AdminUsers.route";
import ArticlePageRoute from "./ArticlePage.route";
import CartRoute from "./Cart.route";
import CatalogRoute from "./Catalog.route";
import DiscountsRoute from "./Discounts.route";
import HomeRoute from "./Home.route";
import NewsArticleRoute from "./NewsArticle.route";
import NewsPageRoute from "./NewsPage.route";
import OrderRoute from "./Order.route";
import OrdersRoute from "./Orders.route";
import PersonalDataPolicy from "./PersonalDataPolicy.route";
import ProductPageRoute from "./ProductPage.route";
import ProgramPageRoute from "./ProgramPage.route";
import RefererTreeRoute from "./RefererTree.route";
import UdachlivayaPage from "./UdachlivayaPage.route";
import IntellektualnayaPage from "./IntellektualnayaPage.route";

export default [
  AccountSettingsRoute,
  AdminMAConfigRoute,
  AdminOrdersRoute,
  AdminRoute,
  AdminStatsActiveOrdersRoute,
  AdminStatsRoute,
  AdminUserDetailsRoute,
  AdminUsers,
  CartRoute,
  CatalogRoute,
  DiscountsRoute,
  HomeRoute,
  NewsArticleRoute,
  NewsPageRoute,
  OrderRoute,
  OrdersRoute,
  PersonalDataPolicy,
  ProductPageRoute,
  ProgramPageRoute,
  RefererTreeRoute,
  UdachlivayaPage,
  IntellektualnayaPage,

  // THIS ROUTE MUST BE THE LAST ONE!
  ArticlePageRoute,
] as const;
