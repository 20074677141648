import { Button, SxProps, Theme } from "@mui/material";
import React from "react";
import { MAMenu } from "./MAMenu";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

//export type MAMenuButtonProps = Omit<IconButtonProps, "href" | "children" | "ref">;
export interface MAMenuButtonProps {
  sx?: SxProps<Theme>;
}

export const MAMenuButton = (props: MAMenuButtonProps) => {
  const menuAnchorRef = React.createRef<HTMLAnchorElement>();

  const [anchorEl, setAnchorEl] = React.useState({
    maMenu: null as HTMLElement | null,
  });

  function openMAMenu() {
    setAnchorEl((x) => ({ ...x, maMenu: menuAnchorRef.current }));
  }

  function closeMAMenu() {
    setAnchorEl((x) => ({ ...x, maMenu: null }));
  }

  return (
    <>
      <Button href="" ref={menuAnchorRef} onClick={openMAMenu} {...props}>
        Алгоритм
      </Button>
      <MAMenu
        anchorEl={anchorEl.maMenu}
        onClose={closeMAMenu}
        PaperProps={{ sx: { mt: "8px" } }}
      />
    </>
  );
};
