/**
 * @generated SignedSource<<85011b7b7fa5c6f7db69fbfd17d0b8a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountsQuery$variables = {};
export type DiscountsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DiscountsFragment">;
};
export type DiscountsQuery = {
  variables: DiscountsQuery$variables;
  response: DiscountsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DiscountsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DiscountsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RangeDiscount",
        "kind": "LinkedField",
        "name": "discounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "i",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "from",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "to",
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCnt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "potentialPurchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attractedPurchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "PM",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProgram",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUnspentDiscount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Coupon",
            "kind": "LinkedField",
            "name": "coupons",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "finishTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CouponType",
                "kind": "LinkedField",
                "name": "couponType",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4222909ad8f7a01ee812cfa5b9112823",
    "id": null,
    "metadata": {},
    "name": "DiscountsQuery",
    "operationKind": "query",
    "text": "query DiscountsQuery {\n  ...DiscountsFragment\n}\n\nfragment DiscountMenuFragment on Query {\n  discounts {\n    i\n    from\n    to\n    discount\n  }\n}\n\nfragment DiscountsFragment on Query {\n  ...DiscountMenuFragment\n  ...TopDiscountHintFragment\n  me {\n    maInfo {\n      isInProgram\n      hasUnspentDiscount\n      referralCode\n      id\n    }\n    coupons {\n      id\n      discount\n      finishTime\n      couponType {\n        title\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment TopDiscountHintFragment on Query {\n  discounts {\n    i\n    from\n    to\n    discount\n  }\n  me {\n    maInfo {\n      ...UserMAInfoFragment\n      id\n    }\n    id\n  }\n}\n\nfragment UserMAInfoFragment on UserMAInfo {\n  id\n  referralCode\n  referralId\n  referralCnt\n  purchasesAmount\n  potentialPurchasesAmount\n  attractedPurchasesAmount\n  PM\n  isInProgram\n  hasUnspentDiscount\n}\n"
  }
};
})();

(node as any).hash = "d7211a53e299af3d0edad0e65be7ef6c";

export default node;
