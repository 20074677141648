import { Image } from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  Stack,
} from "@mui/material";
import { ArrayItemType } from "dinno-util";
import React from "react";
import { graphql, useFragment } from "react-relay";
import { useConfig } from "../core";
import { useUrlSetPath } from "../core/history";
import CatalogCategoriesFragment, {
  CatalogCategoriesFragment$data,
  CatalogCategoriesFragment$key,
} from "../queries/CatalogCategoriesFragment.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

graphql`
  fragment CatalogCategoriesFragment on Query {
    categories(parentId: null) {
      edges {
        node {
          id
          name
          navName
          picture
          children {
            id
            name
            navName
          }
        }
      }
    }
  }
`;

type Categories = Exclude<CatalogCategoriesFragment$data["categories"], null>;
type Edges = Exclude<Categories["edges"], null>;
type Category = Exclude<ArrayItemType<Edges>, null>["node"];

interface CatalogCategoriesProps {
  fragmentRef: CatalogCategoriesFragment$key;
  gridMode: boolean;
  onClose?: () => void;
}

export const CatalogCategories = ({
  fragmentRef,
  gridMode,
  onClose,
}: CatalogCategoriesProps) => {
  const data = useFragment<CatalogCategoriesFragment$key>(
    CatalogCategoriesFragment,
    fragmentRef
  );
  const [selected, setSelected] = React.useState<Category>(null);

  const switchCategory = React.useCallback(
    (node: Category) => {
      setSelected(node);
    },
    [setSelected]
  );
  const config = useConfig();

  const setPath = useUrlSetPath("/catalog/");
  const navigate = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, node: Category) => {
      event.preventDefault();
      setPath(node?.navName ?? null);
      if (onClose) onClose();
    },
    [setPath]
  );
  const navigate2 = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, navName: string | null) => {
      event.preventDefault();
      setPath(navName);
      if (onClose) onClose();
    },
    [setPath]
  );
  return (
    <>
      {gridMode && (
        <Grid container>
          {data.categories?.edges?.map(
            (edge) =>
              edge?.node && (
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  key={edge?.node?.id}
                >
                  <MenuItem
                    href=""
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                      navigate(e, edge?.node ?? null)
                    }
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                  >
                    {edge?.node?.picture && (
                      <Box
                        marginX={2}
                        marginY={3}
                        maxWidth="220px"
                        component="img"
                        src={edge?.node?.picture}
                        sx={{
                          width: {
                            xs: "150px",
                            sm: "220px",
                            md: "170px",
                            lg: "220px",
                          },
                          borderRadius: "50%",
                          MozBorderRadius: "50%",
                          WebkitBorderRadius: "50%",
                        }}
                        alt="Иконка категории"
                      />
                    )}
                    {!edge?.node?.picture && <Image fontSize="large" />}
                    {edge?.node?.name}
                  </MenuItem>
                </Grid>
              )
          )}
        </Grid>
      )}
      {!gridMode && (
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <div>
            {data.categories?.edges?.map((edge) => (
              <MenuItem
                key={edge?.node?.id}
                component={Link}
                onMouseEnter={(_e: React.MouseEvent<HTMLAnchorElement>) =>
                  switchCategory(edge?.node ?? null)
                }
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  navigate(e, edge?.node ?? null)
                }
              >
                <ListItemText primary={edge?.node?.name} />
              </MenuItem>
            ))}
          </div>
          <div>
            {selected?.children?.map((child) => (
              <MenuItem
                key={child?.id}
                component={Link}
                onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                  navigate2(e, child?.navName ?? null)
                }
              >
                {child?.name}
              </MenuItem>
            ))}
          </div>
        </Stack>
      )}
    </>
  );
};
