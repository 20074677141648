/**
 * @generated SignedSource<<acd1e56e1f4a063320c9c77de1132c00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type LoginDialogCheckUserQuery$variables = {
  username?: string | null;
};
export type LoginDialogCheckUserQuery$data = {
  readonly checkUser: boolean | null;
};
export type LoginDialogCheckUserQuery = {
  variables: LoginDialogCheckUserQuery$variables;
  response: LoginDialogCheckUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "kind": "ScalarField",
    "name": "checkUser",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginDialogCheckUserQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginDialogCheckUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "431f502216c3f963e941270702738811",
    "id": null,
    "metadata": {},
    "name": "LoginDialogCheckUserQuery",
    "operationKind": "query",
    "text": "query LoginDialogCheckUserQuery(\n  $username: String\n) {\n  checkUser(username: $username)\n}\n"
  }
};
})();

(node as any).hash = "4543749cba4b2a8572e48248c3b229d6";

export default node;
