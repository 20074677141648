/**
 * @generated SignedSource<<3a32a870adca4cd804e14e2cc8fd2c2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserInput = {
  id?: string | null;
  username?: string | null;
  name?: string | null;
  oldPassword?: string | null;
  password?: string | null;
  phone?: string | null;
  admin?: boolean | null;
  inviteCode?: string | null;
  coupons?: ReadonlyArray<UpdateUserInput_coupons> | null;
  intellectualDiscountWord?: string | null;
};
export type UpdateUserInput_coupons = {
  id?: string | null;
  discount?: number | null;
  startTime?: any | null;
  finishTime?: any | null;
  couponTypeId?: string | null;
};
export type TopDiscountHintReferralHelperMutation$variables = {
  input: UpdateUserInput;
};
export type TopDiscountHintReferralHelperMutation$data = {
  readonly updateUser: {
    readonly user: {
      readonly maInfo: {
        readonly " $fragmentSpreads": FragmentRefs<"UserMAInfoFragment">;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"Auth_user">;
    } | null;
    readonly discounts: ReadonlyArray<{
      readonly i: number | null;
      readonly from: number | null;
      readonly to: number | null;
      readonly discount: number | null;
    } | null> | null;
  } | null;
};
export type TopDiscountHintReferralHelperMutation = {
  variables: TopDiscountHintReferralHelperMutation$variables;
  response: TopDiscountHintReferralHelperMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "RangeDiscount",
  "kind": "LinkedField",
  "name": "discounts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "i",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCnt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attractedPurchasesAmount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopDiscountHintReferralHelperMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Auth_user"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMAInfo",
                "kind": "LinkedField",
                "name": "maInfo",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserMAInfoFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopDiscountHintReferralHelperMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPayload",
        "kind": "LinkedField",
        "name": "updateUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Picture",
                "kind": "LinkedField",
                "name": "picture",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserMAInfo",
                "kind": "LinkedField",
                "name": "maInfo",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referralCode",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "referralId",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "purchasesAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "potentialPurchasesAmount",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "PM",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInProgram",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasUnspentDiscount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "777188fd19b74f5f82b7f49776548df3",
    "id": null,
    "metadata": {},
    "name": "TopDiscountHintReferralHelperMutation",
    "operationKind": "mutation",
    "text": "mutation TopDiscountHintReferralHelperMutation(\n  $input: UpdateUserInput!\n) {\n  updateUser(input: $input) {\n    user {\n      ...Auth_user\n      maInfo {\n        ...UserMAInfoFragment\n        id\n      }\n      id\n    }\n    discounts {\n      i\n      from\n      to\n      discount\n    }\n  }\n}\n\nfragment Auth_user on User {\n  id\n  username\n  email\n  phone\n  name\n  picture {\n    url\n  }\n  referralCnt\n  attractedPurchasesAmount\n}\n\nfragment UserMAInfoFragment on UserMAInfo {\n  id\n  referralCode\n  referralId\n  referralCnt\n  purchasesAmount\n  potentialPurchasesAmount\n  attractedPurchasesAmount\n  PM\n  isInProgram\n  hasUnspentDiscount\n}\n"
  }
};
})();

(node as any).hash = "b3edf3ac9a2eda91165fedd3b6df5395";

export default node;
