import { ContentCopy } from "@mui/icons-material";
import { Box, BoxProps, Button, IconButton, Link } from "@mui/material";
import React from "react";
import CopyToClipboard from "./CopyToClipboard";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy_ = React.StrictMode; // To prevent automatic removal

export type MyDelaemTsenyDostupnymiProps = BoxProps;

export const MyDelaemTsenyDostupnymi = (
  props: MyDelaemTsenyDostupnymiProps
) => {
  return (
    <Button variant="contained" color="secondary" href="/#kak-poluchit-skidku">
      Мы делаем цены на любые продукты доступными
    </Button>
  );
};
