import { graphql } from "relay-runtime";
import { Route } from "../core";
import AdminOrdersQuery, {
  AdminOrdersQuery as AdminOrdersQueryType,
} from "../queries/AdminOrdersQuery.graphql";
import { AdminOrders } from "./AdminOrders";

graphql`
  query AdminOrdersQuery {
    ...AdminOrdersFragment
  }
`;

/**
 * Discounts chart page route. Intended for development and debugging purposes.
 */
export default {
  path: "/admin/orders",
  query: AdminOrdersQuery,
  component: () => import(/* webpackChunkName: "admin" */ "./AdminOrders"),
  response: (data) => ({
    title: "Список заказов",
    description: "",
    props: { fragmentRef: data },
  }),
} as Route<AdminOrders, AdminOrdersQueryType>;
