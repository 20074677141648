import { Route } from "../core";
import AdminUserDetailsQuery, {
  type AdminUserDetailsQuery as AdminUserDetailsQueryType,
} from "../queries/AdminUserDetailsQuery.graphql";
import { AdminUserDetails } from "./AdminUserDetails";

/**
 * Discounts chart page route. Intended for development and debugging purposes.
 */
export default {
  path: "/admin/user/:id",
  query: AdminUserDetailsQuery,
  component: () => import(/* webpackChunkName: "admin" */ "./AdminUserDetails"),
  response: (data) => ({
    title: "Информация о пользователе",
    description: "",
    props: { fragmentRef: data },
  }),
} as Route<AdminUserDetails, AdminUserDetailsQueryType>;
