import { Close } from "@mui/icons-material";
import {
  Menu, MenuItem, MenuProps
} from "@mui/material";
import React from "react";
import { usePreloadedQuery } from "react-relay";
import { useAppContext } from "../common/AppContextProvider";
import { CatalogCategories } from "../common/CatalogCategories";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

type CatalogMenuProps = Omit<
  MenuProps,
  | "id"
  | "role"
  | "open"
  | "getContentAnchorEl"
  | "anchorOrigin"
  | "transformOrigin"
  | "onClose"
> & {
  onClose: () => void,
  gridMode: boolean
};

function CatalogMenu(props: CatalogMenuProps): JSX.Element {
  const { gridMode, PaperProps, MenuListProps, onClose, ...other } = props;
  const appContext = useAppContext();
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef
  );
  return (
    <Menu
      id="catalog-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      PaperProps={{ ...PaperProps, sx: { ...PaperProps?.sx, maxWidth: "1100px", p: 2, pt: 0 } }}
      MenuListProps={{ ...MenuListProps, dense: true }}
      onClose={onClose}
      {...other}
    >
      <MenuItem sx={{ justifyContent: "flex-end", pr: 1 }} onClick={onClose}>
        <Close fontSize="large" />
      </MenuItem>
      <CatalogCategories fragmentRef={appData} gridMode={gridMode} onClose={onClose} />
    </Menu>
  );
}

export { CatalogMenu, type CatalogMenuProps };
