import { Alert, Box, CircularProgress, Grid } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { graphql, usePaginationFragment } from "react-relay";
import ProductListFragment, {
  ProductListFragment$key,
} from "../queries/ProductListFragment.graphql";
import { ProductCard } from "./ProductCard";

graphql`
  fragment ProductListFragment on Query
  @refetchable(queryName: "ProductListPaginationQuery") {
    products(
      after: $productCursor
      first: $numProducts
      search: $search
      categoryNavName: $categorySlug
    ) @connection(key: "ProductListFragment_products") {
      edges {
        node {
          id
          ...ProductCard_product
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;

export interface ProductListProps {
  productsFragRef: ProductListFragment$key;
  infiniteScroll?: boolean;
}

export const ProductList = ({
  productsFragRef,
  infiniteScroll,
}: ProductListProps) => {
  const { data, loadNext, hasNext } = usePaginationFragment(
    ProductListFragment,
    productsFragRef
  );
  const fetchMoreData = React.useCallback(() => {
    loadNext(24);
  }, [loadNext]);
  const products = data?.products?.edges;
  const grid =
    !products || products.length === 0 ? (
      <Alert severity="info">Товары по данному запросу не найдены.</Alert>
    ) : (
      <Grid
        container
        spacing={{ xs: 2, sm: 2, md: 3, lg: 3, xl: 3 }}
        paddingBottom={3}
      >
        {products?.map(
          (edge) =>
            edge?.node && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={3}
                key={edge?.node?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ProductCard data={edge?.node} />
              </Grid>
            )
        )}
      </Grid>
    );
  return infiniteScroll ? (
    <InfiniteScroll
      dataLength={products ? products.length : 0}
      next={fetchMoreData}
      hasMore={hasNext}
      loader={
        <Box textAlign="center" padding={4} width="100%">
          <CircularProgress size={60} />
        </Box>
      }
    >
      {grid}
    </InfiniteScroll>
  ) : (
    <React.Suspense fallback={<CircularProgress size={60} />}>
      {grid}
    </React.Suspense>
  );
};
