import { type Route } from "../core";
import { NotFoundError } from "../core/errors";
import ArticlePageQuery, {
  ArticlePageQuery as ArticlePageQueryType,
  ArticlePageQuery$data,
} from "../queries/ArticlePageQuery.graphql";
import { ArticlePage } from "./ArticlePage";

export default {
  path: "/:articleSlug",
  query: ArticlePageQuery,
  variables(ctx) {
    return {
      articleSlug: ctx.params?.articleSlug,
    };
  },
  component: () => import(/* webpackChunkName: "home" */ "./ArticlePage"),
  response: (data: ArticlePageQuery$data) => {
    if (data.cmsArticles?.data.length == 0) throw new NotFoundError();
    if ((data.cmsArticles?.data.length ?? 0) > 1)
      console.warn(
        "More than one article found for slug: " +
          data.cmsArticles?.data[0].attributes?.slug
      );
    const article = data.cmsArticles?.data[0];
    if (!article) throw new NotFoundError();

    return {
      props: { data },
      title:
        article.attributes?.title ??
        "купить орехи и сухофрукты в Санкт-Петербурге (СПБ)",
      description:
        article.attributes?.description ??
        "Интернет-магазин орехов и сухофруктов. У нас вы можете купить орехи и сухофрукты на любой вкус недорого и по хорошей цене",
    };
  },
} as Route<ArticlePage, ArticlePageQueryType>;
