export const ruRURub = new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
});
export const ruRUPercent = new Intl.NumberFormat("ru-RU", { style: "percent" });
export const ruRUPercentFrac = (minFraction, maxFraction) => new Intl.NumberFormat("ru-RU", {
    style: "percent",
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
});
export const ruRUNumber = (minFraction, maxFraction) => new Intl.NumberFormat("ru-RU", {
    style: "decimal",
    minimumFractionDigits: minFraction,
    maximumFractionDigits: maxFraction,
});
