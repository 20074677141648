import { type Route } from "../core";
import OrderQuery, {
  type OrderQuery as OrderQueryType,
} from "../queries/OrderQuery.graphql";
import { type OrderPage } from "./Order";

/**
 * Shows one of the user's orders.
 */
export default {
  path: "/order/:readableOrderId",
  query: OrderQuery,
  component: () => import(/* webpackChunkName: "order" */ "./Order"),
  response: (data, ctx) => {
    const isCheckout = !!ctx.query?.get("checkout");
    const orderId = ctx.params ? ctx.params["readableOrderId"] : "";
    return {
      title: isCheckout ? "Ваш заказ" : `Заказ ${orderId}`,
      description: "Подробная информация о заказе",
      props: { data, isCheckout },
    };
  },
} as Route<OrderPage, OrderQueryType>;
