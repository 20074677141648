import { Article } from "../common/Article";
import { type Route } from "../core";
import { news } from "./articles";

/**
 * NewsPage route.
 */
export default {
  path: "/novosti/:articleSlug",
  component: () =>
    import(/* webpackChunkName: "NewsPage" */ "../common/Article"),
  response: (_data, ctx) => {
    const articleSlug = ctx.params?.["articleSlug"];
    if (!articleSlug) throw new Error("Article slug not found!");
    const article = news.find((a) => a.attributes?.slug === articleSlug);
    if (!article) throw new Error(`Article ${articleSlug} not found`);
    return {
      title: article?.attributes?.title,
      description: article?.attributes?.description,
      keywords:
        "новости, акции, интернет-магазин, орехи, сухофрукты, MadNuts.ru",
      ogDescription:
        "Новости и акции интернет-магазина орехов и сухофруктов MadNuts.ru",
      props: { article },
    };
  },
} as Route<Article>;
