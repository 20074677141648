/**
 * @generated SignedSource<<69147b831a6b2195dbb4c616ae59a534>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminOrdersQuery$variables = {};
export type AdminOrdersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminOrdersFragment">;
};
export type AdminOrdersQuery = {
  variables: AdminOrdersQuery$variables;
  response: AdminOrdersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminOrdersQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AdminOrdersFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "all",
            "value": true
          }
        ],
        "concreteType": "OrderConnection",
        "kind": "LinkedField",
        "name": "orders",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrderEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "readableId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "discount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "purchasesAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attractedPurchasesAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "format",
                        "value": "dd.MM.yy HH:mm:ss zzz"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "bonusExpiryTime",
                    "storageKey": "bonusExpiryTime(format:\"dd.MM.yy HH:mm:ss zzz\")"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bonusIsActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isInProgram",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "orders(all:true)"
      }
    ]
  },
  "params": {
    "cacheID": "1cfc047b74fa82d295027ad4deabe026",
    "id": null,
    "metadata": {},
    "name": "AdminOrdersQuery",
    "operationKind": "query",
    "text": "query AdminOrdersQuery {\n  ...AdminOrdersFragment\n}\n\nfragment AdminOrdersFragment on Query {\n  orders(all: true) {\n    edges {\n      node {\n        id\n        status\n        ...AdminOrdersRowFragment\n      }\n    }\n  }\n}\n\nfragment AdminOrdersRowFragment on Order {\n  id\n  readableId\n  status\n  total\n  discount\n  user {\n    username\n    id\n  }\n  purchasesAmount\n  attractedPurchasesAmount\n  bonusExpiryTime(format: \"dd.MM.yy HH:mm:ss zzz\")\n  bonusIsActive\n  isInProgram\n}\n"
  }
};
})();

(node as any).hash = "880d0083f7ddaac631402c2f0623a1dd";

export default node;
