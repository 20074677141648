/**
 * @generated SignedSource<<8f8bd0cf85bd73821d5359cc507fa2ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminMAConfigQuery$variables = {};
export type AdminMAConfigQuery$data = {
  readonly maConfig: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminMAConfigFragment">;
  } | null;
};
export type AdminMAConfigQuery = {
  variables: AdminMAConfigQuery$variables;
  response: AdminMAConfigQuery$data;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminMAConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MAConfig",
        "kind": "LinkedField",
        "name": "maConfig",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminMAConfigFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminMAConfigQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MAConfig",
        "kind": "LinkedField",
        "name": "maConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accumulationPeriodSeconds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "A",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Alfa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Diap0",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "DelMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Diapmax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "KsiVer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "IMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "PM",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "Mbm",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56288a2f18fe63638cc633dfb516a7a5",
    "id": null,
    "metadata": {},
    "name": "AdminMAConfigQuery",
    "operationKind": "query",
    "text": "query AdminMAConfigQuery {\n  maConfig {\n    ...AdminMAConfigFragment\n  }\n}\n\nfragment AdminMAConfigFragment on MAConfig {\n  accumulationPeriodSeconds\n  A\n  Alfa\n  Diap0\n  DelMin\n  Diapmax\n  KsiVer\n  IMax\n  PM\n  Mbm\n}\n"
  }
};

(node as any).hash = "c1a510879c5847ea19f2f4d0a6c53b12";

export default node;
