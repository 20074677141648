import { ContentCopy } from "@mui/icons-material";
import { Box, BoxProps, IconButton } from "@mui/material";
import React from "react";
import CopyToClipboard from "./CopyToClipboard";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy_ = React.StrictMode; // To prevent automatic removal

export type ReferralCodeProps = BoxProps & {
  referralCode: string | null;
};

export const ReferralCode = (props: ReferralCodeProps) => {
  return (
    <>
      {props.referralCode && (
        <Box component="b" sx={{ whiteSpace: "nowrap" }} {...props}>
          {props.referralCode}
          <CopyToClipboard>
            {({ copy }) => (
              <IconButton onClick={() => copy(props.referralCode)}>
                <ContentCopy />
              </IconButton>
            )}
          </CopyToClipboard>
        </Box>
      )}
    </>
  );
};
