import { type Route } from "../core";
import CartQuery, {
  type CartQuery as CartQueryType
} from "../queries/CartQuery.graphql";
import { type CartPage } from "./Cart";

/**
 * Cart route.
 */
export default {
  path: "/cart",
  query: CartQuery,
  component: () => import(/* webpackChunkName: "cart" */ "./Cart"),
  response: (data) => ({
    title: "Корзина",
    description: "Корзина для покупок",
    props: { data },
  }),
} as Route<CartPage, CartQueryType>;
