import { PersonOutline } from "@mui/icons-material";
import {
  ExtendButtonBaseTypeMap,
  IconButton,
  SxProps,
  Theme,
} from "@mui/material";
import {
  OverridableTypeMap,
  OverrideProps,
} from "@mui/material/OverridableComponent";
import React from "react";
import { UserMenu } from ".";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

export type UserMenuButtonProps = Omit<
  OverrideProps<ExtendButtonBaseTypeMap<OverridableTypeMap>, "a">,
  "children" | "component" | "color"
> & {
  sx?: SxProps<Theme>;
};

export const UserMenuButton = (props: UserMenuButtonProps) => {
  const menuAnchorRef = React.createRef<HTMLAnchorElement>();

  const [anchorEl, setAnchorEl] = React.useState({
    userMenu: null as HTMLElement | null,
  });

  function openUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: menuAnchorRef.current }));
  }

  function closeUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: null }));
  }

  return (
    <>
      <IconButton
        href=""
        ref={menuAnchorRef}
        onClick={openUserMenu}
        color="inherit"
        {...props}
      >
        <PersonOutline />
      </IconButton>
      <UserMenu
        anchorEl={anchorEl.userMenu}
        onClose={closeUserMenu}
        PaperProps={{ sx: { mt: "8px" } }}
      />
    </>
  );
};
