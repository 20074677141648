/**
 * @generated SignedSource<<97a286b39cb4085dcfec5b78978d4699>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginDialogSendCodeToEmailMutation$variables = {
  username: string;
};
export type LoginDialogSendCodeToEmailMutation$data = {
  readonly resetPassword: {
    readonly codeIsSent: boolean | null;
  } | null;
};
export type LoginDialogSendCodeToEmailMutation = {
  variables: LoginDialogSendCodeToEmailMutation$variables;
  response: LoginDialogSendCodeToEmailMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "ResetPasswordPayload",
    "kind": "LinkedField",
    "name": "resetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "codeIsSent",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginDialogSendCodeToEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginDialogSendCodeToEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "546f7c4d67ab367a1c308274a7f23cc6",
    "id": null,
    "metadata": {},
    "name": "LoginDialogSendCodeToEmailMutation",
    "operationKind": "mutation",
    "text": "mutation LoginDialogSendCodeToEmailMutation(\n  $username: String!\n) {\n  resetPassword(username: $username) {\n    codeIsSent\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fb9f0bf4efd73734ab1df449b531983";

export default node;
