/**
 * @generated SignedSource<<f3557f95b52594350deeb92d274cadd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TopDiscountHintFragment$data = {
  readonly discounts: ReadonlyArray<{
    readonly i: number | null;
    readonly from: number | null;
    readonly to: number | null;
    readonly discount: number | null;
  } | null> | null;
  readonly me: {
    readonly maInfo: {
      readonly " $fragmentSpreads": FragmentRefs<"UserMAInfoFragment">;
    } | null;
  } | null;
  readonly " $fragmentType": "TopDiscountHintFragment";
};
export type TopDiscountHintFragment$key = {
  readonly " $data"?: TopDiscountHintFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TopDiscountHintFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopDiscountHintFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RangeDiscount",
      "kind": "LinkedField",
      "name": "discounts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "i",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "from",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "to",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserMAInfo",
          "kind": "LinkedField",
          "name": "maInfo",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserMAInfoFragment"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "9ecd2c5457a0d51d53d613835180ff6d";

export default node;
