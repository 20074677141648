import { Environment, Network, RecordSource, Store } from "relay-runtime";
import { RecordMap } from "relay-runtime/lib/store/RelayStoreTypes";
import { load } from "./ssrUtil";

type Config = {
  endpointUrl: string;
  records?: ConstructorParameters<typeof RecordSource>[0];
};

/* eslint-disable @typescript-eslint/no-explicit-any */

function createRelay(config: Config): Environment {
  const recordSource = new RecordSource(config.records);
  const store = new Store(recordSource);

  const network = Network.create((operation, variables): Promise<any> => {
    return fetch(config.endpointUrl, {
      method: "POST",
      headers: {
        "content-Type": "application/json",
      },
      body: JSON.stringify({ query: operation.text, variables }),
      credentials: "include",
    }).then((res) => res.json());
  });

  return new Environment({
    store,
    network,
    handlerProvider: null,
  });
}

const data = load<RecordMap>("data");
export const relay = createRelay({ endpointUrl: "/api", records: data });
