import { Route } from "../core";
import { Admin } from "./Admin";

export default {
  path: "/admin",
  component: () => import(/* webpackChunkName: "admin" */ "./Admin"),
  response: (_) => ({
    title: "Главная страница админки",
    description: "Главная страница админки",
  }),
} as Route<Admin>;
