import type {
  History as HistoryBase,
  Location as LocationBase,
  To,
} from "history";
import { Action } from "history";
import * as React from "react";

export type History = HistoryBase;
export type Location = LocationBase;

// Provide the default history object (for unit testing)
export const HistoryContext = React.createContext<History>({
  action: Action.Pop,
  location: { key: "", pathname: "/", search: "" },
} as History);

// Provide the default location object (for unit testing)
export const LocationContext = React.createContext<Location>({
  key: "",
  pathname: "/",
  search: "",
} as Location);

function isLeftClickEvent(event: React.MouseEvent<HTMLElement>) {
  return event.button === 0;
}

function isModifiedEvent(event: React.MouseEvent<HTMLElement>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

export function useHistory(): History {
  return React.useContext(HistoryContext);
}

export function useLocation(): Location {
  return React.useContext(LocationContext);
}

export function useURLSearchParams(): URLSearchParams {
  const { search } = React.useContext(LocationContext);
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function useUrlSetQueryParam(paramName: string, pathname?: string) {
  const history = useHistory();
  return React.useCallback(
    (value: string | null, replace = false) => {
      const newParams = new URLSearchParams(history.location.search);
      if (value) newParams.set(paramName, value);
      else newParams.delete(paramName);
      const newPathname = pathname ?? history.location.pathname;
      history[replace ? "replace" : "push"](
        `${newPathname}?${newParams.toString()}`
      );
    },
    [history, history.location.pathname, history.location.search]
  );
}

export function useUrlSetPath(
  basePath?: string,
  method: "push" | "replace" = "push"
) {
  const history = useHistory();
  return React.useCallback(
    (path: string | null) => {
      const params = new URLSearchParams(history.location.search);
      const newPath =
        basePath === undefined
          ? path === null
            ? history.location.pathname
            : path
          : path === null
          ? basePath
          : (basePath.slice(-1) === "/" ? basePath : basePath + "/") + path;
      history[method](`${newPath}?${params.toString()}`);
    },
    [history, history.location.pathname, history.location.search]
  );
}

export function useNavigate<T extends HTMLElement = HTMLAnchorElement>(
  method: "push" | "replace" = "push"
): React.MouseEventHandler<T> {
  const history = useHistory();

  return React.useCallback(
    (event: React.MouseEvent<T>): void => {
      if (
        event.defaultPrevented ||
        isModifiedEvent(event) ||
        !isLeftClickEvent(event)
      ) {
        return;
      }

      event.preventDefault();
      history[method]?.(event.currentTarget.getAttribute("href") as To);
    },
    [history]
  );
}
