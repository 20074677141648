import { type ThemeOptions } from "@mui/material/styles";

type Func = () => NonNullable<ThemeOptions["typography"]>;

/**
 * Customized Material UI typography.
 *
 * @see https://mui.com/customization/typography/
 * @see https://mui.com/customization/default-theme/?expand-path=$.typography
 */
const createTypography: Func = () => ({
  fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
  h1: {
    fontWeight: 600,
    fontSize: "2.5rem",
  },
  h2: {
    fontWeight: 600,
    fontSize: 32,
  },
  h3: {
    fontWeight: 600,
    fontSize: 27,
  },
  h4: {
    fontWeight: 600,
  },
  h5: {
    fontWeight: 500,
  },
  h6: {
    fontWeight: 500,
  },
  body1: {
    fontSize: 18,
  },
  body2: {
    fontSize: 14,
  },
});

export { createTypography };
