/**
 * @generated SignedSource<<f7ed186b3cf034ab0a58b23fadea59a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NewsPageQuery$variables = {};
export type NewsPageQuery$data = {
  readonly cmsArticles: {
    readonly data: ReadonlyArray<{
      readonly id: string | null;
      readonly attributes: {
        readonly slug: string | null;
        readonly title: string | null;
        readonly description: string;
        readonly cover: {
          readonly data: {
            readonly attributes: {
              readonly url: string;
              readonly alternativeText: string | null;
            } | null;
          } | null;
        } | null;
        readonly publishedAt: any | null;
      } | null;
    }>;
  } | null;
};
export type NewsPageQuery = {
  variables: NewsPageQuery$variables;
  response: NewsPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "category": {
        "slug": {
          "eq": "novosti"
        }
      }
    }
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "id:desc"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CMSUploadFile",
  "kind": "LinkedField",
  "name": "attributes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alternativeText",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CMSArticleEntityResponseCollection",
        "kind": "LinkedField",
        "name": "cmsArticles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CMSArticleEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CMSArticle",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CMSUploadFileEntityResponse",
                    "kind": "LinkedField",
                    "name": "cover",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CMSUploadFileEntity",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "cmsArticles(filters:{\"category\":{\"slug\":{\"eq\":\"novosti\"}}},sort:\"id:desc\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewsPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CMSArticleEntityResponseCollection",
        "kind": "LinkedField",
        "name": "cmsArticles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CMSArticleEntity",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CMSArticle",
                "kind": "LinkedField",
                "name": "attributes",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CMSUploadFileEntityResponse",
                    "kind": "LinkedField",
                    "name": "cover",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CMSUploadFileEntity",
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "cmsArticles(filters:{\"category\":{\"slug\":{\"eq\":\"novosti\"}}},sort:\"id:desc\")"
      }
    ]
  },
  "params": {
    "cacheID": "225d5a100aa3e395ec03a41efce039e9",
    "id": null,
    "metadata": {},
    "name": "NewsPageQuery",
    "operationKind": "query",
    "text": "query NewsPageQuery {\n  cmsArticles(filters: {category: {slug: {eq: \"novosti\"}}}, sort: \"id:desc\") {\n    data {\n      id\n      attributes {\n        slug\n        title\n        description\n        cover {\n          data {\n            attributes {\n              url\n              alternativeText\n            }\n            id\n          }\n        }\n        publishedAt\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1d02c89bda18e15f7394389cd894d17";

export default node;
