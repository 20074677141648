import React from "react";
import { graphql } from "react-relay";
import { useMutationPromise } from "../core/mutationPromise";

const addToCartMutation = graphql`
  mutation addToCartCartControlAddtMutation($productId: ID) {
    addToCart(input: { productId: $productId }) {
      cart {
        ...CartFragment
      }
    }
  }
`;

export const useAddToCart = () => {
  const [commit] = useMutationPromise(addToCartMutation);
  return React.useCallback(
    async function (productId: string) {
      return await commit({ variables: { productId } });
    },
    [commit]
  );
};
