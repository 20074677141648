import { type Route } from "../core";
import { type AdminStats } from "./AdminStats";
import AdminStatsQuery, {
  type AdminStatsQuery as AdminStatsQueryType,
} from "../queries/AdminStatsQuery.graphql";

/**
 * MA stats.
 */
export default {
  path: "/admin/stats",
  query: AdminStatsQuery,
  component: () => import(/* webpackChunkName: "admin" */ "./AdminStats"),
  response: (data) => ({
    title: "Статистика (админка)",
    description: "Статистика алгоритма",
    props: { data },
  }),
} as Route<AdminStats, AdminStatsQueryType>;
