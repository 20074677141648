import { useScrollTrigger } from "@mui/material";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

type ElevationScrollProps = { children: React.ReactElement };

export const ElevationScroll = (props: ElevationScrollProps) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 1,
  });
};
