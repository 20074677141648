import { Badge, BadgeProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 8,
    top: 6,
    backgroundColor: `${theme.palette.secondary.main}`,
  },
}));
