import { BoxProps, Button } from "@mui/material";
import React from "react";
import { useAddToCart } from "./addToCart";

export type AddToCartControlProps = BoxProps & {
  productId: string;
  buttonText?: string;
};

export const AddToCartSpecialControl = (props: AddToCartControlProps) => {
  // const appContext = useAppContext();
  // const appData = usePreloadedQuery(
  //   AppContextProviderQuery,
  //   appContext.queryRef
  // );
  // const cart = useFragment<CartFragment$key>(CartFragment, appData.cart);
  // const cart
  //const isAdded = cart?.items?.some((item) => item?.id === props.productId);

  const boxProps: Partial<AddToCartControlProps> = { ...props };
  delete boxProps.productId;

  const addToCartHook = useAddToCart();
  const addToCart = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      addToCartHook(props.productId);
    },
    [props.productId]
  );
  return (
    <Button sx={{ my: 2 }} variant="outlined" onClick={addToCart}>
      {props.buttonText}
    </Button>
  );
};
