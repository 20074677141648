import { type Route } from "../core";
import CatalogQuery, {
  CatalogQuery as CatalogQueryType,
} from "../queries/CatalogQuery.graphql";
import { getQueryVars, type Catalog } from "./Catalog";

/**
 * Catalogpage route.
 */
export default {
  path: "/catalog/:categoryNavName?",
  variables: getQueryVars,
  query: CatalogQuery,
  component: () => import(/* webpackChunkName: "catalog" */ "./Catalog"),
  response: (data) => ({
    title: "Каталог орехов и сухофруктов",
    description:
      "Все орехи, цукаты, сухофрукты и ореховые меси с бесплатной доставкой в СПБ при покупке от 4000",
    keywords:
      "купить в Санкт-Петербурге макадамию, кешью, пекан, фисташки, бразильский орех по низкой цене",
    //ogUrl: "https://www.madnuts.ru/catalog", // Just for reference
    ogTitle: "Каталог орехов и сухофруктов", // Add: " - MadNuts.ru"
    ogDescription:
      "Все орехи, цукаты и ореховые смеси с бесплатной доставкой в СПб при покупке от 2000₽!⚡MadNuts⚡",
    //ogType: "website" // Just for reference
    ogImage:
      "https://static.tildacdn.com/tild3533-3838-4562-a532-393562333636/Screenshot_7.png",
    props: { data },
  }),
} as Route<Catalog, CatalogQueryType>;
