import { AccountTree, BarChart, Settings } from "@mui/icons-material";
import {
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import React from "react";
import { useNavigate } from "../core";

type MAMenuProps = Omit<
  MenuProps,
  | "id"
  | "role"
  | "open"
  | "getContentAnchorEl"
  | "anchorOrigin"
  | "transformOrigin"
>;

function MAMenu(props: MAMenuProps): JSX.Element {
  const { PaperProps, MenuListProps, ...other } = props;
  const navigate = useNavigate();
  function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    props.onClose?.(event, "backdropClick");
    navigate(event);
  }

  return (
    <Menu
      id="ma-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      PaperProps={{ ...PaperProps, sx: { ...PaperProps?.sx, width: 320 } }}
      MenuListProps={{ ...MenuListProps, dense: true }}
      {...other}
    >
      <MenuItem component={Link} href="/referers" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <AccountTree />
        </ListItemIcon>
        <ListItemText primary="Дерево рефереров" />
      </MenuItem>
      <MenuItem component={Link} href="/admin/stats" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <BarChart />
        </ListItemIcon>
        <ListItemText primary="Статистика" />
      </MenuItem>
      <MenuItem
        component={Link}
        href="/admin/stats/activeOrders"
        onClick={handleClick}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          <BarChart />
        </ListItemIcon>
        <ListItemText primary="Статистика по активным заказам" />
      </MenuItem>
      <MenuItem component={Link} href="/admin/ma-config" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Настройки" />
      </MenuItem>
      {/* <MenuItem component={Link} href="/admin/simulation" onClick={handleClick}>
        <ListItemIcon sx={{ minWidth: 40 }}>
          <ModelTraining />
        </ListItemIcon>
        <ListItemText primary="Симуляция" />
      </MenuItem> */}
    </Menu>
  );
}

export { MAMenu, type MAMenuProps };
