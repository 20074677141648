import { Inbox, Mail } from "@mui/icons-material";
import { Box, Button, Divider, Drawer, DrawerProps, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";

export type NavigationDrawerProps = DrawerProps & {
  open: boolean,
  onClose: () => void
};

export default function NavigationDrawer(props: NavigationDrawerProps) {
  const onCloseDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    props.onClose();
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={props.open}
        onClose={onCloseDrawer}
      >
        {props.children}
      </Drawer>
    </>
  );
}