/**
 * @generated SignedSource<<ed7b359096f0332384e26c79fbb2dfe9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdminStatsActiveOrdersQuery$variables = {};
export type AdminStatsActiveOrdersQuery$data = {
  readonly adminStats: {
    readonly activeOrders: {
      readonly numberOfOrders: number | null;
      readonly sumOfOrders: number | null;
      readonly sumOfDiscounts: number | null;
      readonly maxDiscount: number | null;
      readonly maxDiscountOrder: {
        readonly readableId: string | null;
      } | null;
    } | null;
  } | null;
};
export type AdminStatsActiveOrdersQuery = {
  variables: AdminStatsActiveOrdersQuery$variables;
  response: AdminStatsActiveOrdersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfOrders",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumOfOrders",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumOfDiscounts",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxDiscount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readableId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminStatsActiveOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminStats",
        "kind": "LinkedField",
        "name": "adminStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActiveOrdersStats",
            "kind": "LinkedField",
            "name": "activeOrders",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "maxDiscountOrder",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminStatsActiveOrdersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminStats",
        "kind": "LinkedField",
        "name": "adminStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ActiveOrdersStats",
            "kind": "LinkedField",
            "name": "activeOrders",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "maxDiscountOrder",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3d36bc8b1a0815fa561789bdfc5d87b",
    "id": null,
    "metadata": {},
    "name": "AdminStatsActiveOrdersQuery",
    "operationKind": "query",
    "text": "query AdminStatsActiveOrdersQuery {\n  adminStats {\n    activeOrders {\n      numberOfOrders\n      sumOfOrders\n      sumOfDiscounts\n      maxDiscount\n      maxDiscountOrder {\n        readableId\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb0a31eae2e1df68ab694a5610ad5eb3";

export default node;
