import { Route } from "../core";
import AdminUsersQuery, {
  type AdminUsersQuery as AdminUsersQueryType,
} from "../queries/AdminUsersQuery.graphql";
import { AdminUsers } from "./AdminUsers";

/**
 * Discounts chart page route. Intended for development and debugging purposes.
 */
export default {
  path: "/admin/users",
  query: AdminUsersQuery,
  component: () => import(/* webpackChunkName: "admin" */ "./AdminUsers"),
  response: (data) => ({
    title: "Список пользователей",
    description: "",
    props: { fragmentRef: data },
  }),
} as Route<AdminUsers, AdminUsersQueryType>;
