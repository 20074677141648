/**
 * @generated SignedSource<<73e8fc4c9f1a138dbfdbb05921f840f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartQuery$variables = {};
export type CartQuery$data = {
  readonly cartOptions: {
    readonly " $fragmentSpreads": FragmentRefs<"CartOptions_data">;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"DiscountMenuFragment" | "TopDiscountHintFragment">;
};
export type CartQuery = {
  variables: CartQuery$variables;
  response: CartQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DiscountMenuFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TopDiscountHintFragment"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CartOptions",
        "kind": "LinkedField",
        "name": "cartOptions",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CartOptions_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "RangeDiscount",
        "kind": "LinkedField",
        "name": "discounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "i",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "from",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "to",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCnt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "potentialPurchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attractedPurchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "PM",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProgram",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUnspentDiscount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CartOptions",
        "kind": "LinkedField",
        "name": "cartOptions",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "comment",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "773870393722d7aed74ee4a0ddf5d366",
    "id": null,
    "metadata": {},
    "name": "CartQuery",
    "operationKind": "query",
    "text": "query CartQuery {\n  ...DiscountMenuFragment\n  ...TopDiscountHintFragment\n  cartOptions {\n    ...CartOptions_data\n    id\n  }\n}\n\nfragment CartOptions_data on CartOptions {\n  id\n  name\n  email\n  phone\n  address\n  comment\n  paymentType\n}\n\nfragment DiscountMenuFragment on Query {\n  discounts {\n    i\n    from\n    to\n    discount\n  }\n}\n\nfragment TopDiscountHintFragment on Query {\n  discounts {\n    i\n    from\n    to\n    discount\n  }\n  me {\n    maInfo {\n      ...UserMAInfoFragment\n      id\n    }\n    id\n  }\n}\n\nfragment UserMAInfoFragment on UserMAInfo {\n  id\n  referralCode\n  referralId\n  referralCnt\n  purchasesAmount\n  potentialPurchasesAmount\n  attractedPurchasesAmount\n  PM\n  isInProgram\n  hasUnspentDiscount\n}\n"
  }
};
})();

(node as any).hash = "0568387e7b537202af70576fdef6a5cc";

export default node;
