/**
 * @generated SignedSource<<da59815308051a3fc619a05b07f05930>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartFragment$data = {
  readonly items: ReadonlyArray<{
    readonly id: string;
    readonly amount: number | null;
    readonly price: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"CartItemFragment">;
  } | null> | null;
  readonly fullSum: number | null;
  readonly discounts: {
    readonly discounts: ReadonlyArray<{
      readonly name: string | null;
      readonly discount: number | null;
      readonly discountedSum: number | null;
      readonly discountSum: number | null;
    } | null> | null;
    readonly discount: number | null;
    readonly discountSum: number | null;
  } | null;
  readonly total: number | null;
  readonly " $fragmentType": "CartFragment";
};
export type CartFragment$key = {
  readonly " $data"?: CartFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CartFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discount",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "discountSum",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CartFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CartItem",
      "kind": "LinkedField",
      "name": "items",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CartItemFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullSum",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Discounts",
      "kind": "LinkedField",
      "name": "discounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Discount",
          "kind": "LinkedField",
          "name": "discounts",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountedSum",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "type": "Cart",
  "abstractKey": null
};
})();

(node as any).hash = "370437ac96dc731303ec0c2f21bda697";

export default node;
