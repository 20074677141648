/**
 * @generated SignedSource<<8607bd9f287599ab8f186a79ac9c8ee5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UdachlivayaPageQuery$variables = {};
export type UdachlivayaPageQuery$data = {
  readonly me: {
    readonly maInfo: {
      readonly isInProgram: boolean | null;
      readonly referralCode: string | null;
      readonly referralCodeDigits: string | null;
      readonly referralCodeDigitsSum: number | null;
      readonly parentReferralCode: string | null;
      readonly parentReferralCodeDigits: string | null;
      readonly parentReferralCodeDigitsSum: number | null;
    } | null;
  } | null;
};
export type UdachlivayaPageQuery = {
  variables: UdachlivayaPageQuery$variables;
  response: UdachlivayaPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInProgram",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCode",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCodeDigits",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCodeDigitsSum",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentReferralCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentReferralCodeDigits",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentReferralCodeDigitsSum",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UdachlivayaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UdachlivayaPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3143f5f9ad557ed936ce8cb387f76544",
    "id": null,
    "metadata": {},
    "name": "UdachlivayaPageQuery",
    "operationKind": "query",
    "text": "query UdachlivayaPageQuery {\n  me {\n    maInfo {\n      isInProgram\n      referralCode\n      referralCodeDigits\n      referralCodeDigitsSum\n      parentReferralCode\n      parentReferralCodeDigits\n      parentReferralCodeDigitsSum\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7bc9078c63b0015cfefa948d1c428ce";

export default node;
