/**
 * @generated SignedSource<<08e17a9fa9ffd1cb400e197451baa659>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CatalogBreadcrumbsFragment$data = {
  readonly categoryPath: ReadonlyArray<{
    readonly id: string;
    readonly name: string | null;
    readonly navName: string | null;
    readonly level: number | null;
  } | null> | null;
  readonly " $fragmentType": "CatalogBreadcrumbsFragment";
};
export type CatalogBreadcrumbsFragment$key = {
  readonly " $data"?: CatalogBreadcrumbsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CatalogBreadcrumbsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "breadcrumbHeadNavName"
    },
    {
      "kind": "RootArgument",
      "name": "breadcrumbHeadType"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CatalogBreadcrumbsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "navName",
          "variableName": "breadcrumbHeadNavName"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "breadcrumbHeadType"
        }
      ],
      "concreteType": "Category",
      "kind": "LinkedField",
      "name": "categoryPath",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "navName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "level",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1a3ac02c2345d49cb193433beb182b50";

export default node;
