import { type Route } from "../core";
import RefererTreeQuery, {
  type RefererTreeQuery as RefererTreeQueryType,
} from "../queries/RefererTreeQuery.graphql";
import { RefererTreePage } from "./RefererTree";

/**
 * Shows user's orders.
 */
export default {
  path: "/referers",
  query: RefererTreeQuery,
  component: () =>
    import(/* webpackChunkName: "refererTree" */ "./RefererTree"),
  response: (data) => {
    return {
      title: "Ваши рефералы",
      description: "Реферальное дерево ваших рефереров",
      props: { data },
    };
  },
} as Route<RefererTreePage, RefererTreeQueryType>;
