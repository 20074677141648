/**
 * @generated SignedSource<<5b24e5bfd3336ef0cecb287a19edb996>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductPageFragment$data = {
  readonly id: string;
  readonly name: string | null;
  readonly description: string | null;
  readonly image: string | null;
  readonly images: ReadonlyArray<{
    readonly image: string | null;
  } | null> | null;
  readonly price: number | null;
  readonly maker: string | null;
  readonly unit: string | null;
  readonly unitPrice: number | null;
  readonly axis1: {
    readonly name: string | null;
    readonly editionId: string | null;
    readonly editions: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null;
    } | null> | null;
  } | null;
  readonly editions: ReadonlyArray<{
    readonly id: string;
    readonly navName: string | null;
    readonly axis1Value: {
      readonly id: string;
    } | null;
  } | null> | null;
  readonly " $fragmentType": "ProductPageFragment";
};
export type ProductPageFragment$key = {
  readonly " $data"?: ProductPageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductPageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductPageFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductImage",
      "kind": "LinkedField",
      "name": "images",
      "plural": true,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maker",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductAxis",
      "kind": "LinkedField",
      "name": "axis1",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "editionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Edition",
          "kind": "LinkedField",
          "name": "editions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdition",
      "kind": "LinkedField",
      "name": "editions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "navName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Edition",
          "kind": "LinkedField",
          "name": "axis1Value",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "01df0e83b5e9f540b4dca3a58d021901";

export default node;
