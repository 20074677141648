import React from "react";
import { useMutation, UseMutationConfig } from "react-relay";
import {
  GraphQLTaggedNode,
  MutationParameters,
  PayloadError,
} from "relay-runtime";

export type UseMutationPromiseConfig = Omit<
  UseMutationConfig<MutationParameters>,
  "onCompleted" | "onError"
>;

export type CommitPromiseResult<Input extends MutationParameters> = {
  data: Input["response"];
  errors: PayloadError[] | null;
};

export function useMutationPromise<Input extends MutationParameters>(
  mutation: GraphQLTaggedNode
): [
  (config: UseMutationPromiseConfig) => Promise<CommitPromiseResult<Input>>,
  boolean
] {
  const [commit, inProgress] = useMutation<Input>(mutation);
  const commitPromise = React.useCallback(
    (config: UseMutationPromiseConfig): Promise<CommitPromiseResult<Input>> => {
      return new Promise<CommitPromiseResult<Input>>((resolve, reject) => {
        commit({
          onCompleted(res, errors) {
            resolve({ data: res, errors });
          },
          onError(error) {
            reject(error);
          },
          ...config,
        });
      });
    },
    [commit]
  );
  return [commitPromise, inProgress];
}
