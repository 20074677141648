/**
 * @generated SignedSource<<2c34f78d8d703b062e95a69b57fafab1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserMAInfoFragment$data = {
  readonly id: string;
  readonly referralCode: string | null;
  readonly referralId: string | null;
  readonly referralCnt: number | null;
  readonly purchasesAmount: number | null;
  readonly potentialPurchasesAmount: number | null;
  readonly attractedPurchasesAmount: number | null;
  readonly PM: number | null;
  readonly isInProgram: boolean | null;
  readonly hasUnspentDiscount: boolean | null;
  readonly " $fragmentType": "UserMAInfoFragment";
};
export type UserMAInfoFragment$key = {
  readonly " $data"?: UserMAInfoFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserMAInfoFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserMAInfoFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referralCnt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchasesAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "potentialPurchasesAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attractedPurchasesAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "PM",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isInProgram",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUnspentDiscount",
      "storageKey": null
    }
  ],
  "type": "UserMAInfo",
  "abstractKey": null
};

(node as any).hash = "742ccc53a621301ebf7c71478b970946";

export default node;
