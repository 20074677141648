/**
 * @generated SignedSource<<17c6e5a9f1323e9f0c233c91cca087b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderQuery$variables = {
  orderId?: string | null;
  readableOrderId?: string | null;
};
export type OrderQuery$data = {
  readonly order: {
    readonly readableId: string | null;
    readonly cart_options: {
      readonly address: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"OrderDetailsFragment">;
  } | null;
};
export type OrderQuery = {
  variables: OrderQuery$variables;
  response: OrderQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "readableOrderId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "orderId"
  },
  {
    "kind": "Variable",
    "name": "readableId",
    "variableName": "readableOrderId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readableId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CartOptions",
            "kind": "LinkedField",
            "name": "cart_options",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrderDetailsFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Order",
        "kind": "LinkedField",
        "name": "order",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CartOptions",
            "kind": "LinkedField",
            "name": "cart_options",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "comment",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "format",
                "value": "d MMM"
              }
            ],
            "kind": "ScalarField",
            "name": "created",
            "storageKey": "created(format:\"d MMM\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mayBeCancelled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CartItem",
            "kind": "LinkedField",
            "name": "items",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "navName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Payment",
            "kind": "LinkedField",
            "name": "payment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "navigateTo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullSum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sumOfDiscount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isInProgram",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "purchasesAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "potentialPurchasesAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "attractedPurchasesAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maDiscount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountedSum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maDiscountSum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "couponDiscount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "couponDiscountSum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dbeacdd4207b7c5fa82b8f51d68052f3",
    "id": null,
    "metadata": {},
    "name": "OrderQuery",
    "operationKind": "query",
    "text": "query OrderQuery(\n  $orderId: ID\n  $readableOrderId: String\n) {\n  order(id: $orderId, readableId: $readableOrderId) {\n    readableId\n    cart_options {\n      address\n      id\n    }\n    ...OrderDetailsFragment\n    id\n  }\n}\n\nfragment OrderDetailsFragment on Order {\n  id\n  readableId\n  status\n  created(format: \"d MMM\")\n  mayBeCancelled\n  items {\n    id\n    price\n    amount\n    product {\n      name\n      navName\n      image\n      id\n    }\n  }\n  cart_options {\n    name\n    email\n    phone\n    address\n    comment\n    paymentType\n    id\n  }\n  payment {\n    navigateTo\n    paymentStatus\n  }\n  fullSum\n  discount\n  sumOfDiscount\n  isInProgram\n  purchasesAmount\n  potentialPurchasesAmount\n  attractedPurchasesAmount\n  maDiscount\n  discountedSum\n  maDiscountSum\n  couponDiscount\n  couponDiscountSum\n  total\n}\n"
  }
};
})();

(node as any).hash = "8d4f6f3b1ebd12df18eff279933bc8d7";

export default node;
