/**
 * @generated SignedSource<<3e134ab2e7a6d519883a075dcda78eb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdminStatsQuery$variables = {};
export type AdminStatsQuery$data = {
  readonly adminStats: {
    readonly global: {
      readonly numberOfUsers: number | null;
      readonly numberOfOrders: number | null;
      readonly sumOfOrders: number | null;
      readonly sumOfDiscounts: number | null;
      readonly maxDiscount: number | null;
      readonly maxDiscountOrder: {
        readonly readableId: string | null;
      } | null;
      readonly maxLevel: number | null;
      readonly ASR: number | null;
      readonly jjSR: number | null;
      readonly LL: number | null;
      readonly Ll: number | null;
    } | null;
    readonly levels: ReadonlyArray<{
      readonly level: number | null;
      readonly avgDiscount: number | null;
      readonly avgReferralCnt: number | null;
      readonly avgOrderTotal: number | null;
      readonly avgPurchasesAmount: number | null;
      readonly avgAttractedPurchasesAmount: number | null;
      readonly userCnt: number | null;
    } | null> | null;
  } | null;
};
export type AdminStatsQuery = {
  variables: AdminStatsQuery$variables;
  response: AdminStatsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfUsers",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfOrders",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumOfOrders",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sumOfDiscounts",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxDiscount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "readableId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maxLevel",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ASR",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jjSR",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LL",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Ll",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "LevelInfo",
  "kind": "LinkedField",
  "name": "levels",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "level",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avgDiscount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avgReferralCnt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avgOrderTotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avgPurchasesAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avgAttractedPurchasesAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userCnt",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminStats",
        "kind": "LinkedField",
        "name": "adminStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalStats",
            "kind": "LinkedField",
            "name": "global",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "maxDiscountOrder",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AdminStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminStats",
        "kind": "LinkedField",
        "name": "adminStats",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GlobalStats",
            "kind": "LinkedField",
            "name": "global",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Order",
                "kind": "LinkedField",
                "name": "maxDiscountOrder",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d450d669b1f2e37a1823fd478e427d2f",
    "id": null,
    "metadata": {},
    "name": "AdminStatsQuery",
    "operationKind": "query",
    "text": "query AdminStatsQuery {\n  adminStats {\n    global {\n      numberOfUsers\n      numberOfOrders\n      sumOfOrders\n      sumOfDiscounts\n      maxDiscount\n      maxDiscountOrder {\n        readableId\n        id\n      }\n      maxLevel\n      ASR\n      jjSR\n      LL\n      Ll\n    }\n    levels {\n      level\n      avgDiscount\n      avgReferralCnt\n      avgOrderTotal\n      avgPurchasesAmount\n      avgAttractedPurchasesAmount\n      userCnt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "40b81c02cad82cd7843ed5cf8e2648cb";

export default node;
