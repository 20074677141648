import * as React from "react";
import {
  fetchQuery,
  graphql,
  useMutation,
  useRelayEnvironment,
} from "react-relay";
import { LoginDialog } from "../dialogs";
import type {
  AuthQuery,
  CredentialsType,
  LoginMethod,
  LoginType,
  User,
} from "./Auth";
import {
  getCurrentUser,
  query,
  selector,
  useFetchUser,
  useSignIn,
  useSignOut,
  variables,
} from "./Auth";

type Auth = {
  /**
   * The currently logged in user object. Or:
   *   - `null` when the user is anonymous
   *   - `undefined` when the authentication status is unknown
   */
  me: User | null | undefined;
  /**
   * Opens a login dialog.
   *
   * @example
   *   signIn() — opens a modal dialog with sign in options
   *   signIn({ method: "GitHub" }) — opens GitHub sign in window
   *   signIn({ type: "onboarding" }) — opens a customized sign in dialog
   */
  signIn: (options?: SignInOptions) => Promise<User | null>;
  /** Clears the authenticated session. */
  signOut: () => Promise<void>;
  /** Fetches the current user info from the API. */
  fetch: () => Promise<User | null>;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export type SignInOptions = {
  type?: LoginType;
  method?: LoginMethod;
  credentials?: CredentialsType;
  registerInput?: RegisterUserInput;
  resetInput?: ResetPasswordInput;
};
type SignInState = SignInOptions & {
  open: boolean;
  error?: string;
  errors?: { username?: string[]; password?: string[] };
};
type SignInCallback = [
  resolve: (user: User | null) => void,
  reject: (err: Error) => void
];

export type RegisterUserInput = {
  username: string;
  password: string;
  phone: string;
  inviteCode: string;
};

export type ResetPasswordInput = {
  username: string;
  password: string;
  code: string;
};

const AuthContext = React.createContext<Auth>({
  me: undefined as User | null | undefined,
  signIn: () => Promise.resolve(null),
  signOut: () => Promise.resolve(),
  fetch: () => Promise.resolve(null),
});

const registerMutation = graphql`
  mutation AuthProviderCreateUserMutation($input: CreateUserInput) {
    createUser(input: $input, dryRun: false) {
      user {
        ...Auth_user
      }
    }
  }
`;

const resetPasswordMutation = graphql`
  mutation AuthProviderResetPasswordMutation(
    $username: String!
    $password: String!
    $code: String!
  ) {
    resetPassword(username: $username, password: $password, code: $code) {
      user {
        ...Auth_user
      }
    }
  }
`;

// Pop-up window for Google/Facebook authentication
let loginWindow: WindowProxy | null = null;

function AuthProvider(props: AuthProviderProps): JSX.Element {
  const [state, setState] = React.useState<SignInState>({ open: false });
  const callbackRef = React.useRef<SignInCallback>();
  const relay = useRelayEnvironment();
  const signInHook = useSignIn();
  const signOut = useSignOut();
  const fetch = useFetchUser();

  const [commitRegister] = useMutation(registerMutation);
  const [commitResetPassword] = useMutation(resetPasswordMutation);

  // Attempt to read the current user record (me) from the local store.
  const [snap, setSnap] = React.useState(() => relay.lookup(selector));

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const { method, error, ...loginState } = state;

  // Subscribe to updates
  React.useEffect(() => {
    const subscription = relay.subscribe(snap, setSnap);
    return () => subscription.dispose();
  }, [relay]);

  // Once the component is mounted, attempt to load user info from the API.
  React.useEffect(() => {
    fetchQuery<AuthQuery>(relay, query, variables, {
      fetchPolicy: "store-or-network",
    }).toPromise();
  }, [relay]);

  // Start listening for notifications from the pop-up login window
  React.useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (
        event.origin === window.location.origin &&
        event.source === loginWindow
      ) {
        if (event.data.error) {
          setState((prev: SignInState) => ({
            ...prev,
            open: true,
            error: event.data.error,
          }));
          callbackRef.current?.[1](new Error(event.data.error));
        } else if (event.data.user) {
          fetch().then((user) => {
            setState({ open: false });
            callbackRef.current?.[0](user ?? null);
          });
        } else {
          setState((prev) => ({ ...prev, open: true, register: true }));
        }
      }
    }

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const signIn = React.useCallback<Auth["signIn"]>(function signIn(options) {
    return new Promise((resolve, reject) => {
      const callback = callbackRef.current;
      callbackRef.current = [
        (user: User) => {
          resolve(user);
          callback?.[0](user);
          callbackRef.current = undefined;
        },
        (err: Error) => {
          reject(err);
          callback?.[1](err);
          callbackRef.current = undefined;
        },
      ];

      if (options?.type == "password" && options.credentials) {
        signInHook(options.credentials)
          .then(() => {
            fetch().then((user) => {
              setState({ open: false });
              if (callbackRef.current) callbackRef.current[0](user ?? null);
            });
          })
          .catch((error: { message: string; errors: string[] }) => {
            console.error(error);
            setState((prev: SignInState) => ({
              ...prev,
              open: true,
              error: error.message,
              errors: error.errors,
            }));
            if (callbackRef.current)
              callbackRef.current[1]({
                name: error.message,
                message: error.message,
              });
          });
      } else if (options?.type === "resetPassword" && options.resetInput) {
        commitResetPassword({
          onCompleted(res, errors) {
            if (errors && (errors.length ?? 0) > 0) {
              const error = errors[0] as any;
              setState((prev: SignInState) => ({
                ...prev,
                open: true,
                error: error.message,
                errors: error.extensions?.ltlshop?.user,
              }));
              if (callbackRef.current)
                callbackRef.current[1]({
                  name: error.message,
                  message: error.message,
                });
            } else {
              fetch().then(() => {
                setState({ open: false });
              });
              if (callbackRef.current) callbackRef.current[0](null);
            }
          },
          onError(error) {
            setState((prev: SignInState) => ({
              ...prev,
              open: true,
              error: error.message,
            }));
            if (callbackRef.current)
              callbackRef.current[1]({
                name: error.message,
                message: error.message,
              });
          },
          variables: {
            username: options.resetInput.username,
            password: options.resetInput.password,
            code: options.resetInput.code,
          },
        });
      } else if (options?.type === "register" && options.registerInput) {
        commitRegister({
          onCompleted(res, errors) {
            if (errors && (errors.length ?? 0) > 0) {
              const error = errors[0] as any;
              setState((prev: SignInState) => ({
                ...prev,
                open: true,
                error: error.message,
                errors: error.extensions?.ltlshop?.user,
              }));
              if (callbackRef.current)
                callbackRef.current[1]({
                  name: error.message,
                  message: error.message,
                });
            } else {
              fetch().then(() => {
                setState({ open: false });
              });
              if (callbackRef.current) callbackRef.current[0](null);
            }
          },
          onError(error) {
            setState((prev: SignInState) => ({
              ...prev,
              open: true,
              error: error.message,
            }));
            if (callbackRef.current)
              callbackRef.current[1]({
                name: error.message,
                message: error.message,
              });
          },
          variables: {
            input: {
              username: options.registerInput.username,
              password: options.registerInput.password,
              signIn: true,
              phone: options.registerInput.phone,
              inviteCode: options.registerInput.inviteCode,
            },
          },
        });
      } else if (options?.method) {
        const url = `/api/auth/${options.method.toLowerCase()}`;

        if (loginWindow === null || loginWindow.closed) {
          const width = 520;
          const height = 600;
          const left =
            (window.top?.outerWidth ?? 0) / 2 +
            (window.top?.screenX ?? 0) -
            width / 2;
          const top =
            (window.top?.outerHeight ?? 0) / 2 +
            (window.top?.screenY ?? 0) -
            height / 2;
          loginWindow = window.open(
            url,
            "login",
            `menubar=no,toolbar=no,status=no,width=${width},height=${height},left=${left},top=${top}`
          );
        } else {
          loginWindow.focus();
          loginWindow.location.href = url;
        }
      } else {
        setState({
          error: "",
          errors: {},
          credentials: { username: "", password: "" },
          ...options,
          open: true,
        });
      }
    });
  }, []);

  const auth = React.useMemo(
    () => ({
      me: getCurrentUser(relay, snap),
      signIn,
      signOut,
      fetch,
    }),
    [relay, snap]
  );

  const handleClose = React.useCallback(function handleClose() {
    // Had to change this from the commented out variant to this
    // because otherwise it leads to some uncomprehensible broblem
    // with React Suspense. It happens when I try to refresh AppContext
    // inside the callback

    // setState({ open: false });
    // callbackRef.current?.[0](null);

    fetch().then((user) => {
      setState({ open: false });
      if (callbackRef.current) callbackRef.current[0](user ?? null);
    });
  }, []);

  const handleInput = React.useCallback(
    function handleInput() {
      setState((prev) => ({ ...prev, error: undefined, errors: undefined }));
    },
    [setState]
  );

  return (
    <AuthContext.Provider value={auth}>
      {props.children}
      <LoginDialog
        error={error}
        {...loginState}
        onClose={handleClose}
        onChange={handleInput}
      />
    </AuthContext.Provider>
  );
}

function useAuth(): Auth {
  return React.useContext(AuthContext);
}

function useAuthCallback<T extends unknown[]>(
  callback: (...args: T) => void,
  deps: React.DependencyList
): (...args: T) => void {
  const { me, signIn } = React.useContext(AuthContext);

  return React.useCallback((...args: T) => {
    (args[0] as React.SyntheticEvent)?.preventDefault?.();

    if (me) {
      callback(...args);
    } else {
      signIn().then((user) => {
        if (user) callback(...args);
      });
    }
  }, deps);
}

export { AuthProvider, AuthContext, useAuth, useAuthCallback };
