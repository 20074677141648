import { type Route } from "../core";
import { type AdminStatsActiveOrders } from "./AdminStatsActiveOrders";
import AdminStatsActiveOrdersQuery, {
  type AdminStatsActiveOrdersQuery as AdminStatsActiveOrdersQueryType,
} from "../queries/AdminStatsActiveOrdersQuery.graphql";

/**
 * MA stats.
 */
export default {
  path: "/admin/stats/activeOrders",
  query: AdminStatsActiveOrdersQuery,
  component: () =>
    import(/* webpackChunkName: "admin" */ "./AdminStatsActiveOrders"),
  response: (data) => ({
    title: "Статистика по активным заказам (админка)",
    description:
      "Статистика по заказам, которые участвуют в партнёрской программе и при этом попадают в ТНП",
    props: { data },
  }),
} as Route<AdminStatsActiveOrders, AdminStatsActiveOrdersQueryType>;
