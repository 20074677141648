import { Button, SxProps, Theme } from "@mui/material";
import React from "react";
import { useHandleSignIn } from "./AppContextProvider";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

export interface SignInButtonProps {
  sx?: SxProps<Theme>;
  variant?: "contained" | "outlined";
  children?: JSX.Element | string | null;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

export function SignInButton(props: SignInButtonProps) {
  const handleSignIn = useHandleSignIn();
  return (
    <Button
      sx={props.sx}
      color={props.color ?? "primary"}
      onClick={handleSignIn}
      variant={props.variant ?? "outlined"}
    >
      {props.children ?? "Войти"}
    </Button>
  );
}
