import { type Route } from "../core";
import AccountSettingsQuery, {
  type AccountSettingsQuery as AccountSettingsQueryType,
} from "../queries/AccountSettingsQuery.graphql";
import { AccountSettings } from "./AccountSettings";

/**
 * User account settings route.
 */
export default {
  path: "/settings",
  query: AccountSettingsQuery,
  authorize: true,
  component() {
    return import(/* webpackChunkName: "settings" */ "./AccountSettings");
  },
  response: (data) => ({
    title: "Редактирование учётной записи",
    props: data,
  }),
} as Route<AccountSettings, AccountSettingsQueryType>;
